import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Link } from "react-router-dom";

import './index.css';
import { web3 } from '../../store/web3';
import '../../app/FigureOuch/index.css';
import { networkId, message } from '../../store/config';
import { login, logout, getUserProfile } from '../../store/actions/Auth';

import logo from '../../static/images/logo.png';
import youtube from '../../static/images/youtube.svg';
import facebook from '../../static/images/facebook.svg';
import instagram from '../../static/images/instagram.svg';
import tiktok from '../../static/images/tiktok.svg';
import call from '../../static/images/call.svg';
import email from '../../static/images/email.svg';

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            address: '',
            token: '',
            errorText: ''
        };
    };

    googleTranslateElementInit = () => {
        new window.google.translate.TranslateElement(
            {
                pageLanguage: "en",
                includedLanguages: 'en,zh-TW', // 'en' for English, 'zh-TW' for Traditional Chinese
                layout: google.translate.TranslateElement.InlineLayout.SIMPLE,
            },
            "google_translate_element"
        );
    };

    logout() {
        // Delete all items from local storage to logout the user
        localStorage.clear();
        this.props.logout('');
        this.setState({ token: '', address: '' });
    }

    componentDidMount() {
        this.props.getUserProfile();
        var addScript = document.createElement("script");
        addScript.setAttribute(
            "src",
            "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
        );
        document.body.appendChild(addScript);
        window.googleTranslateElementInit = this.googleTranslateElementInit;
    }

    render() {
        let { publicAddress, activeSection, sticky } = this.props;
        let { isOpen } = this.state;
        return (
            <>
                <div>
                    {/* <div className="top-header">
                        <div className="auto-container">
                            <div className="row">
                                <div className="col-lg-6 col-md-12">
                                    <div className="left-contact-area">
                                        <div className="contcat-box">
                                            <i className="icon"><img src={call} alt='' /></i>
                                            <a href="tell:85235005366">852 35005366</a>
                                        </div>
                                        <div className="contcat-box">
                                            <i className="icon"><img src={email} alt='' /></i>
                                            <a href="mailto:info@figureouch.com">info@figureouch.com</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <div className="right-contact-area">
                                        <ul>
                                            <li>
                                                <a className="social-link" href="https://youtube.com/playlist?list=PL9f6rMnfmaKHHbD1TG46PWNSOlxhFURS0&si=8XOjOGsj3FgTgqBh" target="_blank"><img src={youtube} alt='' /></a>
                                            </li>
                                            <li>
                                                <a className="social-link" href="https://instagram.com/figureouch?igshid=MzRlODBiNWFlZA==" target="_blank"><img src={instagram} alt='' /></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* <a className="social-link" href="" target="_blank"><img src={facebook} alt='' /></a> */}
                    {/* <a className="social-link" href="" target="_blank"><img src={tiktok} alt='' /></a> */}
                    <header>
                        <div className={`figure-ouch-nav ${sticky && 'sticky-nav'}`}>
                            <nav className='navbar navbar-expand-lg sidenav' id="sidenav-1" data-mdb-hidden="false">
                                <div className='container-fluid'>
                                    <div className="inner-container">
                                        <Link className='navbar-brand' to='/'><img src={logo} alt='' /></Link>
                                        <button onClick={() => this.setState({ isOpen: true })} className='navbar-toggler' type='button' data-toggle='collapse'
                                            data-target='#navbarSupportedContent' aria-controls='navbarSupportedContent' aria-expanded='false'
                                            aria-label='Toggle navigation' aria-haspopup="true">
                                            <i class='icon' aria-hidden='true'><img src='images/mobile-menu.png' alt='' /></i>
                                        </button>
                                        <div className='collapse navbar-collapse nav-links' id='navbarSupportedContent'>
                                            <div className="mobile-menu-logo">
                                                <Link className='navbar-brand' to='/'><img src={logo} alt='' /></Link>
                                                <button onClick={() => this.setState({ isOpen: true })} className='navbar-toggler' type='button' data-toggle='collapse'
                                                    data-target='#navbarSupportedContent' aria-controls='navbarSupportedContent' aria-expanded='false'
                                                    aria-label='Toggle navigation' aria-haspopup="true">
                                                    <i class='icon' aria-hidden='true'><img src='images/menu-close.png' alt='' /></i>
                                                </button>
                                            </div>

                                            <ul className='navbar-nav sidenav-menu'>
                                                <li className='nav-item'>
                                                    <Link smooth to="" className={`nav-link ${activeSection == 'Banner' && 'active'}`}>
                                                        Home
                                                    </Link>
                                                </li>
                                                {/* <li className='nav-item'>
                                                    <Link smooth to="/Gallery" className={`nav-link ${activeSection == 'Gallery' && 'active'}`}>
                                                        Gallery
                                                    </Link>
                                                </li> */}
                                                <li className='nav-item'>
                                                    <Link smooth to="/Buy" className={`nav-link ${activeSection == 'Buy' && 'active'}`}>
                                                        Buy
                                                    </Link>
                                                </li>
                                                {/* <li className='nav-item'>
                                                    <Link smooth to="/nicola" className={`nav-link ${activeSection == 'nicola' && 'active'}`}>
                                                        Event Ticket
                                                    </Link>
                                                </li> */}
                                                {/* <li className='nav-item'>
                                                    <Link smooth to="/Voting" className={`nav-link ${activeSection == 'Voting' && 'active'}`}>
                                                        Voting
                                                    </Link>
                                                </li> */}
                                            </ul>
                                            {!this.props.isLogin &&
                                                <div className="searchbox">
                                                    <Link to="Login" className="btn-style-one" type="button">
                                                        Sign In
                                                    </Link>
                                                    {/* <div id="google_translate_element"></div> */}
                                                </div>
                                            }

                                            {this.props.isLogin &&
                                                <div className="searchbox">
                                                    <Link to="/UpdateProfile" className="btn-style-one" type="button">
                                                        Edit Profile
                                                    </Link>
                                                    {/* <div id="google_translate_element"></div> */}
                                                </div>
                                            }
                                            {/* <div id="google_translate_element"></div> */}
                                        </div >
                                    </div >
                                </div >
                            </nav>
                        </div >
                    </header>
                    {!this.props.isLogin &&
                        <Link className="btn-style-two fixed-btn" to="Login">Sign up</Link>
                    }
                </div>
            </>
        );
    }
}
const mapDispatchToProps = {
    login,
    logout,
    getUserProfile
};

const mapStateToProps = ({ Auth }) => {
    let { isLogin } = Auth;
    return { isLogin }
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);