import { connect } from "react-redux";
import React, { Component } from "react";
import Button from '@mui/material/Button';
import EventBus from 'eventing-bus';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Link , Redirect, withRouter} from "react-router-dom";
import validator from 'validator';
import { toggleLoader, login, setUserHaveEmail,setUserForget } from "../../store/actions/Auth";
import './index.css';

class Login extends Component {
  constructor(props) {
    super();
    this.state = {
      email: '',
      password: '',
      conformPassword:'',
      verifyingCode:'',
      forgetActivity:'',

    };
  };

  handleEditChange = (e) => this.setState({ [e.target.name]: e.target.value });

  submitForm = async () => {
    let { email } = this.state;
    let { toggleLoader, setUserHaveEmail} = this.props;
    let isValis = validator.isEmail(email);
    if(!isValis) return EventBus.publish("error", "Please enter valid email");
    toggleLoader({status:true,message:"user email verifying..."});
    setUserHaveEmail({email})
  }

  submitForget = () => {
    let { email,password,conformPassword,verifyingCode } = this.state;
    if(!password) return EventBus.publish("error", "Please enter password");
    if(!conformPassword) return EventBus.publish("error", "Please enter conform password");
    if(!email) return EventBus.publish("error", "Please enter valid email");
    if(!verifyingCode) return EventBus.publish("error", "Please enter code");
    this.props.toggleLoader({status:true,message:"user forget password..."});
    this.props.setUserForget({email,verificationCode:verifyingCode,password})
  }

  componentDidUpdate(prevProps,prevState){
    if(prevProps.getUserHaveEmail !== this.props.getUserHaveEmail){
      if(this.props.getUserHaveEmail == true ){
        this.setState({forgetActivity:true})
      }
    }
    if(prevProps.getUserForget !== this.props.getUserForget){
      if(this.props.getUserForget == true ){
        this.props.history.push("/Login");
      }
    }
  }

  render() {
    let { email, password,verifyingCode, conformPassword, forgetActivity } = this.state;

    if(this.props.isLogin) return <Redirect to="/" />
    return (
      <div className="login-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
                <div className="log-area">
                   <Link to="/"> <img src="images/logo.png" alt="" /></Link>
                </div>
            </div>
          </div>
        </div>
        <div className="form-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3 col-md-12">
              {!forgetActivity ?
              <div className="form-block">
                <div className="title-area">
                  <p className="login-title">Forgot Password</p>
                </div>
                <ValidatorForm className="validator-form">
                  <div className='form-group'>
                    <label htmlFor='email'>Email </label>
                    <TextValidator
                        fullWidth
                        type='email'
                        name='email'
                        value={email}
                        id='standard-full-width'
                        className='form-input-field'
                        onChange={this.handleEditChange}
                      />
                  </div>
                  <div className='form-group btn-group'>
                      <button type="Submit" className='btn-style-one' onClick={this.submitForm}>
                          FORGOT
                      </button>
                  </div>
                  <div className='form-group links-group'>
                  <p> Go to signin page  <Link className="req-pass" to="/Login"> Sign In</Link></p>
                  </div>
                </ValidatorForm>
              </div>
              :
                <div className="form-block">
                <div className="title-area">
                  <p className="login-title">Submit Code & Password</p>
                </div>
                <ValidatorForm className="validator-form">
                  <div className='form-group'>
                    <label htmlFor='email'>Code </label>
                    <TextValidator
                        fullWidth
                        type='text'
                        name='verifyingCode'
                        value={verifyingCode}
                        id='standard-full-width'
                        className='form-input-field'
                        onChange={this.handleEditChange}
                      />
                  </div>
                  <div className='form-group'>
                    <label htmlFor='password'>Password </label>
                    <TextValidator
                        fullWidth
                        type='password'
                        name='password'
                        value={password}
                        id='standard-full-width'
                        className='form-input-field'
                        onChange={this.handleEditChange}
                      />
                  </div>
                  <div className='form-group'>
                    <label htmlFor='password'>Confirm Password </label>
                    <TextValidator
                        fullWidth
                        type='password'
                        name='conformPassword'
                        value={conformPassword}
                        id='standard-full-width'
                        className='form-input-field'
                        onChange={this.handleEditChange}
                      />
                  </div>
                  <div className='form-group btn-group'>
                      <button type="Submit" className='btn-style-one' onClick={this.submitForget}>
                         SUBMIT CODE
                      </button>
                  </div>
                  <div className='form-group links-group'>
                       <p> Go to signin page  <Link className="req-pass" to="/Login"> Sign In</Link></p>
                  </div>
                </ValidatorForm>
              </div>
              }
            </div>
          </div>
        </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = { toggleLoader, login, setUserHaveEmail, setUserForget };

const mapStateToProps = ({ Auth }) => {
  let { isLogin,getUserHaveEmail, getUserForget } = Auth;
  return { isLogin,getUserForget, getUserHaveEmail };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));