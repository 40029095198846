import React, {useEffect} from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import './index.css';
import '../../static/css/animate.css';
import 'animate.css';
import '../../static/css/animate.css';
import { web3 } from '../../store/web3';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import { toggleLoader, getVotings, addVote } from "../../store/actions/Auth";

const Voting = (props) => {

    useEffect(()=>{
        props.toggleLoader({message:"Get votings...", status:true});
        props.getVotings();
    },[]);

    return (
        <div className="wrapper">
            <Navbar />
            <section class="inner-page-banner">
                <div class="auto-container">
                    <div class="row">
                        <div class="col-12">
                            <h1>Voting</h1>
                            <ul className="bredcamb">
                                <li><Link className="bredcamb-link" to="/">Home</Link></li>
                                <li>/</li>
                                <li><Link className="bredcamb-link active" to="#">Voting</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section class="events-sec style-two">
                <div class="auto-container">
                    <div class="row">
                    {props.setVoting && props.setVoting.length > 0 &&
                        <div class="col-12">
                            <div className="sec-title text-center">
                                <p>Voting Pole</p>
                                <h2>Cast Your Vote to support Us</h2>
                            </div>
                        </div>
                    }
                        {/* <div className='col-lg-4 col-md-6 col-sm-12'>
                            <div className="event-item">
                                <div className="text-box">
                                    <h3>Voting Poll title</h3>
                                    <p>Lorem Ipsum is simply dummy text of the printiiig and typesetting industry. </p>
                                    <span className='price'>Item 1 <i className='percentage'>(56%)</i></span>
                                    <span className='price'>Item 2 <i className='percentage'>(44%)</i></span>
                                    <Link className='btn-style-one' to="/CastVote">Vote Now</Link>
                                </div>
                            </div>
                        </div> */}
                        {props.setVoting && props.setVoting.length > 0 &&
                            props.setVoting.map((items,index)=>
                            <div className='col-lg-4 col-md-6 col-sm-12' key={index}>
                                <div className="event-item">
                                    <div className="text-box">
                                        <h3>{items['findVotings']['title']}</h3>
                                        <p>{items['findVotings']['description']}</p>
                                        {/* {items['votingItems'] && items['votingItems'].length > 0 &&

                                        items['votingItems'].map(vots=>
                                            <span className='price'>{vots.title}<i className='percentage'>{vots['votes'].length/items['votingItems'].length * 100} %</i></span>
                                        )
                                        } */}
                                        <Link className='btn-style-one' to={`/CastVote/${items['findVotings']['_id']}`}>Vote Now</Link>
                                    </div>
                                </div>
                            </div>
                        )}
                        {/* <div className='col-lg-4 col-md-6 col-sm-12'>
                            <div className="event-item">
                                <div className="text-box">
                                    <h3>Voting Poll title</h3>
                                    <p>Lorem Ipsum is simply dummy text of the printiiig and typesetting industry. </p>
                                    <span className='price'>Item 1 <i className='percentage'>(56%)</i></span>
                                    <span className='price'>Item 2 <i className='percentage'>(44%)</i></span>
                                    <Link className='btn-style-one' to="/CastVote">Vote Now</Link>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
            <Footer />
        </div >
    );
}

const mapDispatchToProps = { toggleLoader,getVotings,addVote};

const mapStateToProps = ({ Auth }) => {
    let { publicAddress,setVoting } = Auth;
    return { publicAddress,setVoting }
};

export default connect(mapStateToProps, mapDispatchToProps)(Voting);