import { connect } from "react-redux";
import React, { Component } from "react";
import Button from '@mui/material/Button';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import EventBus from 'eventing-bus';
import validator from 'validator';
import { toggleLoader, updateProfile,getUserProfile } from "../../store/actions/Auth";
import './index.css';
import { Link, Redirect, withRouter } from "react-router-dom";
import moment from "moment";

class UpdateProfile extends Component {
  constructor(props) {
    super();
    this.state = {
      name: '',
      email: '',
      phone: '',
      address: '',
      instagram: '',
      DOB: ""
    };
  };

  componentDidMount(){
    this.props.getUserProfile();
  }
  // componentDidUpdate(prevProps){
  //   if(prevProps.userData !== this.props.userData) {
  //     let { name, email, phone, address, instagram, DOB } = this.props.userData;
  //     console.log("**************** name, email, phone, address, instagram, DOB",name, email, phone, address, instagram, DOB);
  //     this.setState({name, email, phone, address, instagram, DOB})
  //   }
  // }

  handleEditChange = (e) => this.setState({ [e.target.name]: e.target.value });

  handleUpdate = async (e) => {
    try {
      e.preventDefault();

      let { name, email, phone, address, instagram, DOB } = this.state;
      let { userData } = this.props;
      // let isValis = validator.isEmail(email);
      // if (!isValis) return EventBus.publish("error", "Email is invalid");
      // if (name == undefined) return  EventBus.publish("error", `Please enter media title`);
      // if (phone == undefined) return  EventBus.publish("error", `Please enter phone`);
      // if (address == undefined) return  EventBus.publish("error", `Please enter address`);
      // if (instagram == undefined) return  EventBus.publish("error", `Please enter instagram`);
      // if (DOB == undefined) return  EventBus.publish("error", `Please enter DOB`);

      const waitFor = (delay) =>
        new Promise((resolve) => setTimeout(resolve, delay));

      if (name !== "") {
        if (!name.replace(/\s/g, '').length) {
          EventBus.publish("error", `Please enter media title`);
          return;
        }

        if (!name.match(/[a-zA-Z]/)) {
          EventBus.publish("error", `Media title must contain alphabets`);
          return;
        }
      }

      if (address !== "") {
        if (!address.replace(/\s/g, '').length) {
          EventBus.publish("error", `Please enter address`);
          return;
        }

        if (!address.match(/[a-zA-Z]/)) {
          EventBus.publish("error", `Address must contain alphabets`);
          return;
        }
      }

      if (phone !== "") {
        if (!phone.replace(/\s/g, '').length) {
          EventBus.publish("error", `Please enter Mobile Number`);
          return;
        }
      }

      if (instagram !== "") {
        if (!instagram.replace(/\s/g, '').length) {
          EventBus.publish("error", `Please enter Instagram Account`);
          return;
        }
      }

      if (DOB !== "") {
        if (!DOB.replace(/\s/g, '').length) {
          EventBus.publish("error", `Please enter DOB`);
          return;
        }
      }

      this.props.toggleLoader({
        message: "Updating profile details...",
        status: true,
      });

      await this.props.updateProfile({ instagram : instagram !== "" ? instagram : userData['instagram'], DOB: DOB ? DOB : userData['DOB'], name: name !== "" ? name : userData['name'], phone: phone !== "" ? phone : userData['phone'], address: address !== "" ? address : userData['address'] });
    } catch (e) {
      console.log(e);
      this.props.toggleLoader({
        message: "Update Not Completed...",
        status: false,
      });
      EventBus.publish("error", `Unable to update!`);
    }
  };

  render() {
    let { name, email, phone, address, instagram, DOB} = this.state;
    let { userData } = this.props;


    return (
      <div className="login-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="log-area">
                <Link to="/"> <img src="images/logo.png" alt="" /></Link>
              </div>
            </div>
          </div>
        </div>
        <div className="form-area">
          <div className="container">
            <div className="row">
              {Object.keys(userData).length > 0 &&
                <div className="col-lg-6 offset-lg-3 col-md-12">
                  <div className="form-block">
                    <div className="title-area">
                      <p className="login-title">Edit Profile</p>
                    </div>
                    <ValidatorForm className="validator-form" onSubmit={this.handleUpdate}>
                      <div className='form-group'>
                        <label htmlFor='fullname'>Full Name </label>
                        <TextValidator
                          fullWidth
                          type='text'
                          name='name'
                          defaultValue={userData['name']}
                          placeholder={userData['name'] ? userData['name'] : name}
                          id='standard-full-width'
                          className='form-input-field'
                          onChange={this.handleEditChange}
                        />
                      </div>
                    
                      <div className='form-group'>
                        <label htmlFor='email'>Mobile Number </label>
                        <TextValidator
                          fullWidth
                          type='number'
                          name='phone'
                          defaultValue={userData['phone']}
                          placeholder={userData['phone'] ? userData['phone'] : phone}
                          id='standard-full-width'
                          className='form-input-field'
                          onChange={this.handleEditChange}
                        />
                      </div>

                      <div className='form-group'>
                        <label htmlFor='email'>Instagram Account</label>
                        <TextValidator
                            fullWidth
                            type='text'
                            name='instagram'
                            defaultValue={userData['instagram']}
                            placeholder={userData['instagram'] ? userData['instagram'] : instagram}
                            id='standard-full-width'
                            className='form-input-field'
                            onChange={this.handleEditChange}
                          />
                      </div>

                      <div className='form-group'>
                        <label htmlFor='email'>DOB</label>
                        <TextValidator
                            fullWidth
                            type='date'
                            name='DOB'
                            defaultValue={userData['DOB'] ? moment(userData['DOB']).format('YYYY-MM-DD') : ""}
                            placeholder={userData['DOB'] ? moment(userData['DOB']).format('YYYY-MM-DD') : DOB}
                            id='standard-full-width'
                            className='form-input-field'
                            onChange={this.handleEditChange}
                          />
                      </div>

                      <div className='form-group'>
                        <label htmlFor='address'>Address </label>
                        <TextValidator
                          fullWidth
                          type='text'
                          name='address'
                          defaultValue={userData['address']}
                          placeholder={userData['address'] ? userData['address'] : address}
                          id='standard-full-width'
                          className='form-input-field'
                          onChange={this.handleEditChange}
                        />
                      </div>
                      <div className='form-group btn-group'>
                        <button type="Submit" className='btn-style-one'>
                          Update
                        </button>
                      </div>
                    </ValidatorForm>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = { toggleLoader, updateProfile, getUserProfile };

const mapStateToProps = ({ Auth }) => {
  let { isLogin, userData } = Auth;
  return { isLogin, userData };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UpdateProfile));