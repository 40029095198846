import moment from "moment";
import Slider from "react-slick";
import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import { Link, withRouter } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';


import './index.css';
import '../../static/css/animate.css';
import 'animate.css';
import '../../static/css/animate.css';
import { web3 } from '../../store/web3';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import validator from 'validator';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { toggleLoader, setRegisterEmail, getMediaItems, submitVotingChoice, getSaleItems, getVotings } from "../../store/actions/Auth";

const FigureOuch = (props) => {

    let [name1, setName1] = useState("");
    let [instagramUrl1, setinstagramUrl1] = useState("");
    let [name2, setName2] = useState("");
    let [instagramUrl2, setinstagramUrl2] = useState("");
    let [name3, setName3] = useState("");
    let [instagramUrl3, setinstagramUrl3] = useState("");
    let [name4, setName4] = useState("");
    let [instagramUrl4, setinstagramUrl4] = useState("");
    let [name5, setName5] = useState("");
    let [instagramUrl5, setinstagramUrl5] = useState("");
    let [name6, setName6] = useState("");
    let [instagramUrl6, setinstagramUrl6] = useState("");
    let [name7, setName7] = useState("");
    let [instagramUrl7, setinstagramUrl7] = useState("");
    let [name8, setName8] = useState("");
    let [instagramUrl8, setinstagramUrl8] = useState("");
    let [name9, setName9] = useState("");
    let [instagramUrl9, setinstagramUrl9] = useState("");
    let [category, setCategory] = useState("Best-Dance-MTV-Director-Awards");
    let [email, setEmail] = useState(props.registerEmail ? props.registerEmail : "");

    const settingsMain = {
        dots: false,
        nav: false,
        arrows: false,
        autoPlay: true,
        speed: 500,
        slidesToShow: 10,
        responsive: true,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,

                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    };

    const settingsEv = {
        dots: false,
        nav: true,
        arrows: true,
        autoPlay: true,
        speed: 500,
        slidesToShow: 3,
        responsive: true,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    };

    let [editData, setEditData] = useState({});
    let [saleData, setSaleData] = useState([]);
    let [mediaData, setMediaData] = useState([]);
    let [modalIsOpen, setModalIsOpen] = useState(false);

    const toggle = () => setModalIsOpen(!modalIsOpen);

    const setModalIsOpenToTrue = (item) => {
        setEditData(item);
        setModalIsOpen(true);
    }

    useEffect(() => {
        // props.getMediaItems();
        props.toggleLoader({message:"Get votings...", status:true});
        props.getVotings();
    }, []);

    useEffect(() => {
        if (props.mediaItems && props.mediaItems.length > 0) {
            if (props.mediaItems.length >= 9) {
                const first5Elements = props.mediaItems.slice(0, 9);
                setMediaData(first5Elements);
            } else {
                setMediaData(props.mediaItems);
            }
        }
    }, [props.mediaItems]);

    const handleImageError = (idx) => {
        mediaData[idx]['image'] = alternate;
        setMediaData([...mediaData]);
    };

    const editImageError = () => {
        editData['image'] = alternate;
        setEditData([...editData]);
    };

    const gotToregisterPage = () => {
        let isValis = validator.isEmail(email);
        if (!isValis) return EventBus.publish("error", "Email is invalid");
        props.setRegisterEmail(email);
        props.history.push("/SignUp");
    }

    // const buySale = () => {
    //     if (!props.isLogin) return props.history.push("/Login");
    //     else return props.history.push(`/Buy`);
    // }

    async function handleEditChange(e) {
        if ([e.target.name] == "category") {
            setCategory(e.target.value);
        } else if ([e.target.name] == "name1") {
            setName1(e.target.value);
        } else if ([e.target.name] == "instagramUrl1") {
            setinstagramUrl1(e.target.value);
        } else if ([e.target.name] == "name2") {
            setName2(e.target.value);
        } else if ([e.target.name] == "instagramUrl2") {
            setinstagramUrl2(e.target.value);
        } else if ([e.target.name] == "name3") {
            setName3(e.target.value);
        } else if ([e.target.name] == "instagramUrl3") {
            setinstagramUrl3(e.target.value);
        } else if ([e.target.name] == "name4") {
            setName4(e.target.value);
        } else if ([e.target.name] == "instagramUrl4") {
            setinstagramUrl4(e.target.value);
        } else if ([e.target.name] == "name5") {
            setName5(e.target.value);
        } else if ([e.target.name] == "instagramUrl5") {
            setinstagramUrl5(e.target.value);
        } else if ([e.target.name] == "name6") {
            setName6(e.target.value);
        } else if ([e.target.name] == "instagramUrl6") {
            setinstagramUrl6(e.target.value);
        } else if ([e.target.name] == "name7") {
            setName7(e.target.value);
        } else if ([e.target.name] == "instagramUrl7") {
            setinstagramUrl7(e.target.value);
        } else if ([e.target.name] == "name8") {
            setName8(e.target.value);
        } else if ([e.target.name] == "instagramUrl8") {
            setinstagramUrl8(e.target.value);
        } else if ([e.target.name] == "name9") {
            setName9(e.target.value);
        } else if ([e.target.name] == "instagramUrl9") {
            setinstagramUrl9(e.target.value);
        }
    }

    async function submitChoice() {
        try {

            const waitFor = (delay) =>
                new Promise((resolve) => setTimeout(resolve, delay));

            console.log("*****************************************");

            if (!props.isLogin) return props.history.push("/Login");

            let submission = [];

            if (name1 !== "" || instagramUrl1 !== "") {
                let updatedCategory = "Best-Dance-MTV-Director-Awards"
                if (name1 == "") {
                    EventBus.publish("error", `Please enter instagram url for ${updatedCategory}`);
                    return;
                }
                if (instagramUrl1 == "") {
                    EventBus.publish("error", `Please enter instagram url for ${updatedCategory}`);
                    return;
                }
                submission.push({ name: name1, instagramUrl: instagramUrl1, category: updatedCategory })
            }

            if (name2 !== "" || instagramUrl2 !== "") {
                let updatedCategory = "Best-Concerts-Choreography-Awards"
                if (name2 == "") {
                    EventBus.publish("error", `Please enter instagram url for ${updatedCategory}`);
                    return;
                }
                if (instagramUrl2 == "") {
                    EventBus.publish("error", `Please enter instagram url for ${updatedCategory}`);
                    return;
                }
                submission.push({ name: name2, instagramUrl: instagramUrl2, category: updatedCategory })
            }

            if (name3 !== "" || instagramUrl3 !== "") {
                let updatedCategory = "Best-MTV-Choreography-Awards"
                if (name3 == "") {
                    EventBus.publish("error", `Please enter instagram url for ${updatedCategory}`);
                    return;
                }
                if (instagramUrl3 == "") {
                    EventBus.publish("error", `Please enter instagram url for ${updatedCategory}`);
                    return;
                }
                submission.push({ name: name3, instagramUrl: instagramUrl3, category: updatedCategory })
            }

            if (name4 !== "" || instagramUrl4 !== "") {
                let updatedCategory = "Best-Dance-Video-Awards"
                if (name4 == "") {
                    EventBus.publish("error", `Please enter instagram url for ${updatedCategory}`);
                    return;
                }
                if (instagramUrl4 == "") {
                    EventBus.publish("error", `Please enter instagram url for ${updatedCategory}`);
                    return;
                }
                submission.push({ name: name4, instagramUrl: instagramUrl4, category: updatedCategory })
            }

            if (name5 !== "" || instagramUrl5 !== "") {
                let updatedCategory = "Best-Live-Dance-Performance-Awards"
                if (name5 == "") {
                    EventBus.publish("error", `Please enter instagram url for ${updatedCategory}`);
                    return;
                }
                if (instagramUrl5 == "") {
                    EventBus.publish("error", `Please enter instagram url for ${updatedCategory}`);
                    return;
                }
                submission.push({ name: name5, instagramUrl: instagramUrl5, category: updatedCategory })
            }

            if (name6 !== "" || instagramUrl6 !== "") {
                let updatedCategory = "Best-MTV-Dance-Awards"
                if (name6 == "") {
                    EventBus.publish("error", `Please enter instagram url for ${updatedCategory}`);
                    return;
                }
                if (instagramUrl6 == "") {
                    EventBus.publish("error", `Please enter instagram url for ${updatedCategory}`);
                    return;
                }
                submission.push({ name: name6, instagramUrl: instagramUrl6, category: updatedCategory })
            }

            if (name7 !== "" || instagramUrl7 !== "") {
                let updatedCategory = "Best-Dancing-Crew"
                if (name7 == "") {
                    EventBus.publish("error", `Please enter instagram url for ${updatedCategory}`);
                    return;
                }
                if (instagramUrl7 == "") {
                    EventBus.publish("error", `Please enter instagram url for ${updatedCategory}`);
                    return;
                }
                submission.push({ name: name7, instagramUrl: instagramUrl7, category: updatedCategory })
            }

            if (name8 !== "" || instagramUrl8 !== "") {
                let updatedCategory = "Best-Male-Dancer"
                if (name8 == "") {
                    EventBus.publish("error", `Please enter instagram url for ${updatedCategory}`);
                    return;
                }
                if (instagramUrl8 == "") {
                    EventBus.publish("error", `Please enter instagram url for ${updatedCategory}`);
                    return;
                }
                submission.push({ name: name8, instagramUrl: instagramUrl8, category: updatedCategory })
            }

            if (name9 !== "" || instagramUrl9 !== "") {
                let updatedCategory = "Best-Female-Dancer"
                if (name9 == "") {
                    EventBus.publish("error", `Please enter instagram url for ${updatedCategory}`);
                    return;
                }
                if (instagramUrl9 == "") {
                    EventBus.publish("error", `Please enter instagram url for ${updatedCategory}`);
                    return;
                }
                submission.push({ name: name9, instagramUrl: instagramUrl9, category: updatedCategory })
            }

            if (submission.length > 0) {
                props.toggleLoader({ message: "Submitting choice...", status: true });

                await props.submitVotingChoice({ submission });

                waitFor(1500);

                setName9("");
                setinstagramUrl9("");
                setCategory("");
            } else {
                EventBus.publish("error", `No choice entred for nominations!`);
                return;
            }
        } catch (e) {
            console.log(e);
            props.toggleLoader({
                message: "Submission Not Completed...",
                status: false,
            });
            EventBus.publish("error", `Unable to submit choice`);
        }
    }

    useEffect(() => {
        props.getSaleItems();
    }, []);

    useEffect(() => {
        if (props.saleItems && props.saleItems.length > 0) {
            setSaleData(props.saleItems);
        }
    }, [props.saleItems]);

    const handleSaleImageError = (idx) => {
        saleData[idx]['image'] = alternate;
        setSaleData([...saleData]);
    };

    const buySale = (itemId) => {
        // if (!props.isLogin) return props.history.push("/Login");
        // else return props.history.push(`/PaymentMethod/${itemId}`);
        return props.history.push(`/PaymentMethod/${itemId}`);
    }

    return (
        <div className="wrapper">
            <Navbar />
            {/* <section class="banner-area">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="video-area">
                                <div dangerouslySetInnerHTML={{
                                    __html: `
                                    <video loop="true" autoPlay="true" muted="false" preload="auto" controls="false" webkit-playsinline="true" playsinline="true">
                                        <source src="videos/intro.mp4" type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                `}}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <br />
            <br />
            <section class="img-sec ">
                <div className="container-fluid">
                    <div className="row">
                        <div class="col-12">
                            <div className="sec-title text-center">
                                <br />
                                <br />
                                <p>Upcoming Event</p>
                                <h2>Figure Ouch! 2023 <br />Annual Awards Presentation & Dance Party</h2>
                            </div>
                        </div>
                        <div className="col-12">
                            <img className="" src="images/poster.png" />
                        </div>
                    </div>
                </div>
            </section>

            <br/>
            <br/>

            {/* <section class="events-sec style-two">
                <div class="auto-container">
                    <div class="row">
                        <div class="col-12">
                            <div className="sec-title text-center">
                                <p>Voting Pole</p>
                                <h2>Cast Your Vote to support Us</h2>
                            </div>
                        </div>
                        {props.setVoting && props.setVoting.length > 0 &&
                            props.setVoting.map((items,index)=>
                            <div className='col-lg-4 col-md-6 col-sm-12' key={index}>
                                <div className="event-item">
                                    <div className="text-box">
                                        <h3>{items['findVotings']['title']}</h3>
                                        <p>{items['findVotings']['description']}</p>
                                        <Link className='btn-style-one' to={`/CastVote/${items['findVotings']['_id']}`}>Vote Now</Link>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </section> */}
            {/* <section class="counter-sec">
                <div class="auto-container">
                    <div class="row">
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="title-area">
                                <h4>We speak with <br />our powerful <br />statistics</h4>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="counter-inner">
                                <div className='counter-start' >
                                    <h1 className='CountUp'>10M+</h1>
                                    <p>Active Listeners</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="counter-inner">
                                <div className='counter-start' >
                                    <h1 className='CountUp'>5K+</h1>
                                    <p>Registrations</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="counter-inner last-counter">
                                <div className='counter-start' >
                                    <h1 className='CountUp'>200+</h1>
                                    <p>Concerts</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            {/* <section class="events-sec style-two">
                <div class="auto-container">
                    <div class="row">
                        <div class="col-12">
                            <div className="sec-title text-center">
                                <p>Voting Poll</p>
                                <h2>Submit your choice for voting polls</h2>
                            </div>
                        </div>
                        <div className="col-lg-6 offset-lg-3 col-md-12">
                            <ValidatorForm className="validator-form">
                                <br />
                                <br />
                                <div className='form-group'>
                                    <h3 htmlFor='name'>幕後獎項 ( Production Awards ) </h3>
                                </div>
                                <br />
                                <br />
                                <div className='form-group'>
                                    <h6 htmlFor='name'>年度舞曲MTV導演獎項 ( Best Dance MTV Director Awards ) </h6>
                                </div>
                                <div className='form-group'>
                                    <label htmlFor='name1'>Name </label>
                                    <TextValidator
                                        fullWidth
                                        type='name1'
                                        name='name1'
                                        value={name1}
                                        id='standard-full-width'
                                        className='form-input-field'
                                        onChange={handleEditChange}
                                    />
                                </div>
                                <div className='form-group'>
                                    <label htmlFor='instagramUrl1'>Instagram URL </label>
                                    <TextValidator
                                        fullWidth
                                        type='instagramUrl1'
                                        name='instagramUrl1'
                                        value={instagramUrl1}
                                        id='standard-full-width'
                                        className='form-input-field'
                                        onChange={handleEditChange}
                                    />
                                </div>
                                <br />
                                <br />
                                <div className='form-group'>
                                    <h6 htmlFor='name'>年度最喜愛演唱會編舞 ( Best Concerts Choreography Awards ) </h6>
                                </div>
                                <div className='form-group'>
                                    <label htmlFor='name2'>Name </label>
                                    <TextValidator
                                        fullWidth
                                        type='name2'
                                        name='name2'
                                        value={name2}
                                        id='standard-full-width'
                                        className='form-input-field'
                                        onChange={handleEditChange}
                                    />
                                </div>
                                <div className='form-group'>
                                    <label htmlFor='instagramUrl2'>Instagram URL </label>
                                    <TextValidator
                                        fullWidth
                                        type='instagramUrl2'
                                        name='instagramUrl2'
                                        value={instagramUrl2}
                                        id='standard-full-width'
                                        className='form-input-field'
                                        onChange={handleEditChange}
                                    />
                                </div>
                                <br />
                                <br />
                                <div className='form-group'>
                                    <h6 htmlFor='name'>年度最喜愛MTV編舞 ( Best MTV Choreography Awards ) </h6>
                                </div>
                                <div className='form-group'>
                                    <label htmlFor='name3'>Name </label>
                                    <TextValidator
                                        fullWidth
                                        type='name3'
                                        name='name3'
                                        value={name3}
                                        id='standard-full-width'
                                        className='form-input-field'
                                        onChange={handleEditChange}
                                    />
                                </div>
                                <div className='form-group'>
                                    <label htmlFor='instagramUrl3'>Instagram URL </label>
                                    <TextValidator
                                        fullWidth
                                        type='instagramUrl3'
                                        name='instagramUrl3'
                                        value={instagramUrl3}
                                        id='standard-full-width'
                                        className='form-input-field'
                                        onChange={handleEditChange}
                                    />
                                </div>
                                <br />
                                <br />
                                <br />
                                <br />
                                <div className='form-group'>
                                    <h3 htmlFor='name'>幕前獎項 ( Performance Awards ) </h3>
                                </div>
                                <br />
                                <br />
                                <div className='form-group'>
                                    <h6 htmlFor='name'>年度舞蹈片段獎項 ( Best Dance Video Awards ) </h6>
                                </div>
                                <div className='form-group'>
                                    <label htmlFor='name4'>Name </label>
                                    <TextValidator
                                        fullWidth
                                        type='name4'
                                        name='name4'
                                        value={name4}
                                        id='standard-full-width'
                                        className='form-input-field'
                                        onChange={handleEditChange}
                                    />
                                </div>
                                <div className='form-group'>
                                    <label htmlFor='instagramUrl4'>Instagram URL </label>
                                    <TextValidator
                                        fullWidth
                                        type='instagramUrl4'
                                        name='instagramUrl4'
                                        value={instagramUrl4}
                                        id='standard-full-width'
                                        className='form-input-field'
                                        onChange={handleEditChange}
                                    />
                                </div>
                                <br />
                                <br />
                                <div className='form-group'>
                                    <h6 htmlFor='name'>年度最喜愛現場舞蹈演出 ( Best Live Dance Performance Awards ) </h6>
                                </div>
                                <div className='form-group'>
                                    <label htmlFor='name5'>Name </label>
                                    <TextValidator
                                        fullWidth
                                        type='name5'
                                        name='name5'
                                        value={name5}
                                        id='standard-full-width'
                                        className='form-input-field'
                                        onChange={handleEditChange}
                                    />
                                </div>
                                <div className='form-group'>
                                    <label htmlFor='instagramUrl5'>Instagram URL </label>
                                    <TextValidator
                                        fullWidth
                                        type='instagramUrl5'
                                        name='instagramUrl5'
                                        value={instagramUrl5}
                                        id='standard-full-width'
                                        className='form-input-field'
                                        onChange={handleEditChange}
                                    />
                                </div>
                                <br />
                                <br />
                                <div className='form-group'>
                                    <h6 htmlFor='name'>年度最喜愛MTV舞蹈演出 ( Best MTV Dance Awards ) </h6>
                                </div>
                                <div className='form-group'>
                                    <label htmlFor='name6'>Name </label>
                                    <TextValidator
                                        fullWidth
                                        type='name6'
                                        name='name6'
                                        value={name6}
                                        id='standard-full-width'
                                        className='form-input-field'
                                        onChange={handleEditChange}
                                    />
                                </div>
                                <div className='form-group'>
                                    <label htmlFor='instagramUrl6'>Instagram URL </label>
                                    <TextValidator
                                        fullWidth
                                        type='instagramUrl6'
                                        name='instagramUrl6'
                                        value={instagramUrl6}
                                        id='standard-full-width'
                                        className='form-input-field'
                                        onChange={handleEditChange}
                                    />
                                </div>
                                <br />
                                <br />
                                <br />
                                <br />
                                <div className='form-group'>
                                    <h3 htmlFor='name'>年度大獎 ( Yearly Awards ) </h3>
                                </div>
                                <br />
                                <br />
                                <div className='form-group'>
                                    <h6 htmlFor='name'>年度最喜愛團隊 ( Best Dancing Crew ) </h6>
                                </div>
                                <div className='form-group'>
                                    <label htmlFor='name7'>Name </label>
                                    <TextValidator
                                        fullWidth
                                        type='name7'
                                        name='name7'
                                        value={name7}
                                        id='standard-full-width'
                                        className='form-input-field'
                                        onChange={handleEditChange}
                                    />
                                </div>
                                <div className='form-group'>
                                    <label htmlFor='instagramUrl7'>Instagram URL </label>
                                    <TextValidator
                                        fullWidth
                                        type='instagramUrl7'
                                        name='instagramUrl7'
                                        value={instagramUrl7}
                                        id='standard-full-width'
                                        className='form-input-field'
                                        onChange={handleEditChange}
                                    />
                                </div>
                                <br />
                                <br />
                                <div className='form-group'>
                                    <h6 htmlFor='name'>年度最喜愛男舞者 ( Best Male Dancer ) </h6>
                                </div>
                                <div className='form-group'>
                                    <label htmlFor='name8'>Name </label>
                                    <TextValidator
                                        fullWidth
                                        type='name8'
                                        name='name8'
                                        value={name8}
                                        id='standard-full-width'
                                        className='form-input-field'
                                        onChange={handleEditChange}
                                    />
                                </div>
                                <div className='form-group'>
                                    <label htmlFor='instagramUrl8'>Instagram URL </label>
                                    <TextValidator
                                        fullWidth
                                        type='instagramUrl8'
                                        name='instagramUrl8'
                                        value={instagramUrl8}
                                        id='standard-full-width'
                                        className='form-input-field'
                                        onChange={handleEditChange}
                                    />
                                </div>
                                <br />
                                <br />
                                <div className='form-group'>
                                    <h6 htmlFor='name'>年度最喜愛女舞者 ( Best Female Dancer ) </h6>
                                </div>
                                <div className='form-group'>
                                    <label htmlFor='name9'>Name </label>
                                    <TextValidator
                                        fullWidth
                                        type='name9'
                                        name='name9'
                                        value={name9}
                                        id='standard-full-width'
                                        className='form-input-field'
                                        onChange={handleEditChange}
                                    />
                                </div>
                                <div className='form-group'>
                                    <label htmlFor='instagramUrl9'>Instagram URL </label>
                                    <TextValidator
                                        fullWidth
                                        type='instagramUrl9'
                                        name='instagramUrl9'
                                        value={instagramUrl9}
                                        id='standard-full-width'
                                        className='form-input-field'
                                        onChange={handleEditChange}
                                    />
                                </div>
                                <div className='form-group btn-group text-center'>
                                    <button type="Submit" className='btn-style-one' onClick={submitChoice}>
                                        Submit
                                    </button>
                                </div>
                            </ValidatorForm>
                        </div>
                    </div>
                </div>
            </section> */}

            <section class="upcoming-sec upcoming-home">
                <div class="auto-container">
                    <div class="row">
                        {/*<div class="col-12">
                            <div className="sec-title text-center">
                                <h2>$2000 包括:</h2>
                                <p>共10小時 導師課堂</p>
                                <p>門票 $880 x 2 (包2杯有酒精（18+）或冇酒精飲品)</p>
                                <span>- 表演者襟章 x 1 (可進出後台)</span><br />
                                <span>- 來賓手帶 x 1</span>
                                <br />
                                <br />
                                <br />
                                <br />
                            </div>
                        </div>
                        */}
                        {
                            saleData.length > 0 &&
                            saleData.map((item, idx) => (
                                <div className='col-lg-4 col-md-6 col-sm-12'>
                                    <div className="event-item">
                                        <div className="img-box">
                                            <img key={idx} src={item['image'] ? item['image'] : alternate} onError={() => handleSaleImageError(idx)} alt="Alternate Image" />
                                        </div>
                                        <div className="text-box">
                                            {
                                                item['description'].split(' ').length > 1 ?
                                                    <>
                                                        <h3>{item['name'] ? item['name'] : "-"}</h3>
                                                        <a href={item['description'].split(' ')[0]} target="_blank">
                                                            {item['description'].split(' ')[0] ?
                                                                <img src="images/instagram.svg" />
                                                                : "-"}
                                                        </a>
                                                        <a href={item['description'].split(' ')[1]} target="_blank">
                                                            {item['description'].split(' ')[1] ?
                                                                <img src="images/instagram.svg" />
                                                                : "-"}
                                                        </a>
                                                    </>
                                                    :
                                                    <>
                                                        <h3>{item['name'] ? `${item['name']} ` : "-"}
                                                            <a href={item['description']} target="_blank">
                                                                {item['description'] ?
                                                                    <img className="buy-link" src="images/instagram.svg" />
                                                                    : "-"}
                                                            </a>
                                                        </h3>
                                                    </>
                                            }
                                            <span className='price'>{item['price'] ? item['price'] : "-"} HKD</span>
                                            {/* <button className='btn-style-one' onClick={() => buySale(item['_id'])}>Buy Now</button> */}
                                            {item['status'] == "active" ?
                                                <button className='btn-style-one' onClick={() => buySale(item['_id'])}>Buy Now</button>
                                                :
                                                <button className='btn-style-one' disabled>Sold</button>
                                            }
                                        </div>
                                    </div>
                                    <br />
                                    <br />
                                </div>
                            ))
                        }
                        {/* <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="event-item">
                                <div className="img-box">
                                    <img src="images/upcoming-event.jpeg" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="event-item">
                                <div className="text-box">
                                    <div className="top-box">
                                        <span>13 November, 2023</span>
                                        <span>Zurich, Switzerland</span>
                                    </div>
                                    <h3>Figure Ouch</h3>
                                    <p>Figure Ouch upcoming event ticket!</p>
                                    <button className="btn-style-one" onClick={buySale}>Buy Ticket</button>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
            {/* <section class="events-sec">
                <div class="auto-container">
                    <div class="row">
                        <div class="col-12">
                            <div className="title-area">
                                <div className="sec-title text-left">
                                    <h2>Checkout our Recent Events</h2>
                                </div>
                                <Link className="view-all" to="/Gallery">View All</Link>
                            </div>
                            <Slider {...settingsEv}>
                                {
                                    mediaData.map((item, idx) => (
                                        <div className="event-item" onClick={() => setModalIsOpenToTrue(item)}>
                                            <div className="img-box">
                                                {
                                                    item['mediaType'] == "image" || item['mediaType'] == "Image" ?
                                                        <img key={idx} src={item['image'] ? item['image'] : alternate} onError={() => handleImageError(idx)} alt="Alternate Image" />
                                                        :
                                                        <video width="320" height="240" controls>
                                                            <source src={editData['image']} type="video/*" />
                                                        </video>
                                                }
                                            </div>
                                            <div className="text-box">
                                                <div className="top-box">
                                                    <span>{item['updatedAt'] ? moment.utc(new Date(item['updatedAt']).getTime()).local().format("dddd, MMMM Do YYYY") : "-/-/-"}</span>
                                                </div>
                                                <h3>{item['name'] ? item['name'] : "-"}</h3>
                                                <p>{item['description'] ? item['description'] : "-"}</p>
                                            </div>
                                        </div>
                                    ))
                                }
                            </Slider>
                        </div>

                    </div>
                </div>
            </section>
            <section class="concert-sec">
                <div class="auto-container">
                    <div class="row">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="title-area">
                                <h4>Want A Free Concert?</h4>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="btn-area">
                                <button className="btn-style-two" onClick={buySale}>Buy Ticket</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* <section class="partners-sec">
                <div class="auto-container">
                    <div class="row">
                        <div class="col-12">
                            <div className="sec-title text-center">
                                <h2>Our Partners</h2>
                            </div>
                            <Slider {...settingsMain}>
                                <div className="item ">
                                    <div className="img-box">
                                        <img src="images/partner-1.png" alt="" />
                                    </div>
                                </div>
                                <div className="item ">
                                    <div className="img-box">
                                        <img src="images/partner-2.png" alt="" />
                                    </div>
                                </div>
                                <div className="item ">
                                    <div className="img-box">
                                        <img src="images/partner-3.png" alt="" />
                                    </div>
                                </div>
                                <div className="item ">
                                    <div className="img-box">
                                        <img src="images/partner-4.png" alt="" />
                                    </div>
                                </div>
                                <div className="item ">
                                    <div className="img-box">
                                        <img src="images/partner-5.png" alt="" />
                                    </div>
                                </div>
                                <div className="item ">
                                    <div className="img-box">
                                        <img src="images/partner-6.png" alt="" />
                                    </div>
                                </div>
                                <div className="item ">
                                    <div className="img-box">
                                        <img src="images/partner-1.png" alt="" />
                                    </div>
                                </div>
                                <div className="item ">
                                    <div className="img-box">
                                        <img src="images/partner-2.png" alt="" />
                                    </div>
                                </div>
                                <div className="item ">
                                    <div className="img-box">
                                        <img src="images/partner-3.png" alt="" />
                                    </div>
                                </div>
                                <div className="item ">
                                    <div className="img-box">
                                        <img src="images/partner-4.png" alt="" />
                                    </div>
                                </div>
                                <div className="item ">
                                    <div className="img-box">
                                        <img src="images/partner-1.png" alt="" />
                                    </div>
                                </div>
                                <div className="item ">
                                    <div className="img-box">
                                        <img src="images/partner-2.png" alt="" />
                                    </div>
                                </div>
                                <div className="item ">
                                    <div className="img-box">
                                        <img src="images/partner-3.png" alt="" />
                                    </div>
                                </div>
                                <div className="item ">
                                    <div className="img-box">
                                        <img src="images/partner-4.png" alt="" />
                                    </div>
                                </div>
                                <div className="item ">
                                    <div className="img-box">
                                        <img src="images/partner-5.png" alt="" />
                                    </div>
                                </div>
                                <div className="item ">
                                    <div className="img-box">
                                        <img src="images/partner-6.png" alt="" />
                                    </div>
                                </div>
                                <div className="item ">
                                    <div className="img-box">
                                        <img src="images/partner-1.png" alt="" />
                                    </div>
                                </div>
                                <div className="item ">
                                    <div className="img-box">
                                        <img src="images/partner-2.png" alt="" />
                                    </div>
                                </div>
                                <div className="item ">
                                    <div className="img-box">
                                        <img src="images/partner-3.png" alt="" />
                                    </div>
                                </div>
                                <div className="item ">
                                    <div className="img-box">
                                        <img src="images/partner-4.png" alt="" />
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>
            <section class="vision-sec">
                <div class="auto-container">
                    <div class="row">
                        <div class="col-12">
                            <div className="sec-title text-center">
                                <p>Our Vision</p>
                                <h2>Figure Out what’s Dream? Figure Ouch!</h2>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="text-box">
                                <h3>Mission</h3>
                                <p>Lorem Ipsum is simply dummy text of the printiiig and typesetting industry. Lorem Ipsum is silyeii dummy text of the printing. Lorem Ipsum is simply dummy text of the printiiig and typesetting industry. Ipsum is simply dummy text of the printiiig and typesetting industry.  Ipsum is simply dummy text of the printiiig and typesetting industry.</p>

                                <h3>Vision</h3>
                                <p>Lorem Ipsum is simply dummy text of the printiiig and typesetting industry. Lorem Ipsum is silyeii dummy text of the printing. Lorem Ipsum is simply dummy text of the printiiig and typesetting industry. Ipsum is simply dummy text of the printiiig and typesetting industry.  Ipsum is simply dummy text of the printiiig and typesetting industry.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="img-box">
                                <img src="images/vision-img.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <Footer />

            {/* ---------------Gallery MODAL--------------- */}

            <Modal
                isOpen={modalIsOpen} toggle={toggle}
                className='main-modal add-media-modal'
            >
                <ModalBody className='modal-body'>
                    {
                        Object.keys(editData).length > 0 &&
                        <div className="event-item">
                            <div className="img-box">
                                {
                                    editData['mediaType'] == "image" || editData['mediaType'] == "Image" ?
                                        <img src={editData['image'] ? editData['image'] : alternate} onError={() => editImageError()} alt="Alternate Image" />
                                        :
                                        <video width="320" height="240" controls>
                                            <source src={editData['image']} type="video/*" />
                                        </video>
                                }
                            </div>
                            <div className="text-box">
                                <h3>{editData['name'] ? editData['name'] : "-"}</h3>
                                <p>{editData['description'] ? editData['description'] : "-"}</p>
                            </div>
                        </div>
                    }
                </ModalBody>
            </Modal>
        </div >
    );
}

const mapDispatchToProps = { toggleLoader, setRegisterEmail, getMediaItems, submitVotingChoice, getSaleItems, getVotings };

const mapStateToProps = ({ Auth }) => {
    let { registerEmail, mediaItems, isLogin, voteResponse, saleItems,setVoting } = Auth;
    return { registerEmail, mediaItems, isLogin, voteResponse, saleItems, setVoting }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FigureOuch));