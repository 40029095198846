import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import '../../app/FigureOuch/index.css';
import './index.css';

import youtube from '../../static/images/youtube-footer.svg';
import facebook from '../../static/images/facebook-footer.svg';
import instagram from '../../static/images/instagram-footer.svg';
import instagrambottom from '../../static/images/footer-insta-bottom.svg';


import youtubBottom from '../../static/images/youtub-bottom.svg';
import facebookBottom from '../../static/images/facebook-bottom.svg';
import tiktokBottom from '../../static/images/tiktok-bottom.svg';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    };

    render() {
        return (
            <footer>
                <div className='main-footer'>
                    <div className='auto-container'>
                        <div className='row'>
                            <div className='col-lg-6 col-md-6 col-sm-12'>
                                <div className='about-footer'>
                                    <ul>
                                        <li>
                                            <a className="social-link" href="https://youtube.com/playlist?list=PL9f6rMnfmaKHHbD1TG46PWNSOlxhFURS0&si=8XOjOGsj3FgTgqBh" target="_blank"><img src={youtube} alt='' /></a>
                                        </li>
                                        <li>
                                            {/* <a className="social-link" href="/" target="_blank"><img src={facebook} alt='' /></a> */}
                                        </li>
                                        <li>
                                            <a className="social-link" href="https://instagram.com/figureouch?igshid=MzRlODBiNWFlZA==" target="_blank"><img src={instagram} alt='' /></a>
                                        </li>
                                        <li>
                                            {/* <a className="social-link" href="/" target="_blank"><img src={tiktok} alt='' /></a> */}
                                        </li>
                                    </ul>
                                    <p>FIGUREOUCH</p>
                                </div>
                            </div>
                            <div className='col-lg-3 col-md-6 col-sm-12'>
                                <div className='link-footer'>
                                    <h5>Navigations</h5>
                                    <ul>
                                        {/* <li>
                                            <Link smooth to="/Gallery" className="social-link" >Gallery</Link>
                                        </li> */}
                                        <li>
                                            <Link smooth to="/Buy" className="social-link">Buy</Link>
                                        </li>
                                        {/* <li>
                                            <Link smooth to="/nicola" className="social-link">Buy Event</Link>
                                        </li> */}
                                        {/* <li>
                                            <Link smooth to="/Voting" className="social-link">Voting</Link>
                                        </li> */}
                                    </ul>
                                </div>
                            </div>
                            <div className='col-lg-3 col-md-6 col-sm-12'>
                                <div className='link-footer'>
                                    <h5>Contact Us</h5>
                                    <ul>
                                        {/* <li>
                                            <a className="social-link" href="tell:85235005366">852 35005366</a>
                                        </li> */}
                                        <li>
                                            <a className="social-link" href="mailto:info@figureouch.com">info@figureouch.com</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='footer-bottom'>
                    <div className='auto-container'>
                        <div className='row'>
                            <div className='col-lg-6 col-md-6 col-sm-12'>
                                <p>© All rights reserved by figureouch, Powered by <a href='https://bitstackinnovations.com' target="_blank">BitStackInnovations</a></p>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-12'>
                                <div className='about-footer'>
                                    <ul>
                                        <li>
                                            <a className="social-link" href="https://youtube.com/playlist?list=PL9f6rMnfmaKHHbD1TG46PWNSOlxhFURS0&si=8XOjOGsj3FgTgqBh" target="_blank"><img src={youtubBottom} alt='' /></a>
                                        </li>
                                        <li>
                                            <a className="social-link" href="https://instagram.com/figureouch?igshid=MzRlODBiNWFlZA==" target="_blank"><img src={instagrambottom} alt='' /></a>
                                        </li>
                                        {/* <li>
                                            <Link className="social-link" to="/" target="_blank"><img src={facebookBottom} alt='' /></Link>
                                        </li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;