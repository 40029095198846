import moment from "moment";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';


import './index.css';
import '../../static/css/animate.css';
import 'animate.css';
import '../../static/css/animate.css';
import { web3 } from '../../store/web3';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import { toggleLoader, getMediaItems } from "../../store/actions/Auth";

const Gallery = (props) => {

    let [editData, setEditData] = useState({});
    let [mediaData, setMediaData] = useState([]);
    let [modalIsOpen, setModalIsOpen] = useState(false);

    const toggle = () => setModalIsOpen(!modalIsOpen);

    const setModalIsOpenToTrue = (item) => {
        setEditData(item);
        setModalIsOpen(true);
    }

    useEffect(() => {
        props.getMediaItems();
    }, []);

    useEffect(() => {
        if (props.mediaItems && props.mediaItems.length > 0) {
            setMediaData(props.mediaItems);
        }
    }, [props.mediaItems]);

    const handleImageError = (idx) => {
        mediaData[idx]['image'] = alternate;
        setMediaData([...mediaData]);
    };

    const editImageError = () => {
        editData['image'] = alternate;
        setEditData([...editData]);
    };

    console.log("***** editData :: ", editData);

    return (
        <div className="wrapper">
            <Navbar />
            <section class="inner-page-banner">
                <div class="auto-container">
                    <div class="row">
                        <div class="col-12">
                            <h1>Gallery</h1>
                            <ul className="bredcamb">
                                <li><Link className="bredcamb-link" to="/">Home</Link></li>
                                <li>/</li>
                                <li><Link className="bredcamb-link active" to="#">Gallery</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section class="events-sec gallery-page">
                <div class="auto-container">
                    <div class="row">
                        <div class="col-12">
                            <div className="sec-title text-center">
                                <p>History</p>
                                <h2>Checkout our Recent Events</h2>
                            </div>
                        </div>
                        {
                            mediaData.length > 0 &&
                            mediaData.map((item, idx) => (
                                <div className='col-lg-4 col-md-6 col-sm-12'>
                                    <div className="event-item" onClick={() => setModalIsOpenToTrue(item)}>
                                        <div className="img-box">
                                            {
                                                item['mediaType'] == "image" || item['mediaType'] == "Image" ?
                                                    <img key={idx} src={item['image'] ? item['image'] : alternate} onError={() => handleImageError(idx)} alt="Alternate Image" />
                                                    :
                                                    <div dangerouslySetInnerHTML={{
                                                        __html: `
                                                        <video loop="true" autoPlay="true" preload="auto" controls="true" webkit-playsinline="true" playsinline="true">
                                                            <source src="https://figure-ouch-assets.s3.ap-southeast-2.amazonaws.com/intro.mp4" type="video/mp4" />
                                                            Your browser does not support the video tag.
                                                        </video>
                                                    `}}></div>
                                            }
                                        </div>
                                        <div className="text-box">
                                            <div className="top-box">
                                                <span>{item['updatedAt'] ? moment.utc(new Date(item['updatedAt']).getTime()).local().format("dddd, MMMM Do YYYY") : "-/-/-"}</span>
                                            </div>
                                            <h3>{item['name'] ? item['name'] : "-"}</h3>
                                            <p>{item['description'] ? item['description'] : "-"}</p>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </section>
            <Footer />

            {/* ---------------Gallery MODAL--------------- */}

            <Modal
                isOpen={modalIsOpen} toggle={toggle}
                className='main-modal add-media-modal'
            >
                <ModalBody className='modal-body'>
                    {
                        Object.keys(editData).length > 0 &&
                        <div className="event-item">
                            <div className="img-box">
                                {
                                    editData['mediaType'] == "image" || editData['mediaType'] == "Image" ?
                                        <img src={editData['image'] ? editData['image'] : alternate} onError={() => editImageError()} alt="Alternate Image" />
                                        :
                                        <div dangerouslySetInnerHTML={{
                                            __html: `
                                            <video loop="true" autoPlay="true" preload="auto" controls="true" webkit-playsinline="true" playsinline="true">
                                                <source src="https://figure-ouch-assets.s3.ap-southeast-2.amazonaws.com/intro.mp4" type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>
                                        `}}></div>
                                }
                            </div>
                            <div className="text-box">
                                <h3>{editData['name'] ? editData['name'] : "-"}</h3>
                                <p>{editData['description'] ? editData['description'] : "-"}</p>
                            </div>
                        </div>
                    }
                </ModalBody>
            </Modal>
        </div >
    );
}

const mapDispatchToProps = {
    toggleLoader,
    getMediaItems
};

const mapStateToProps = ({ Auth }) => {
    let { publicAddress, mediaItems } = Auth;
    return { publicAddress, mediaItems }
};

export default connect(mapStateToProps, mapDispatchToProps)(Gallery);