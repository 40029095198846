import { connect } from "react-redux";
import React, { Component } from "react";
import Button from '@mui/material/Button';
import EventBus from 'eventing-bus';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Link, Redirect } from "react-router-dom";
import validator from 'validator';
import jwt_decode from 'jwt-decode';
import { GoogleLogin } from '@react-oauth/google';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { toggleLoader, login, getRegisterUser, getVerifyCode, getUserHaveEmail, getUserForget } from "../../store/actions/Auth";
import './index.css';

class Login extends Component {
  constructor(props) {
    super();
    this.state = {
      email: '',
      password: '',
    };
  };

  handleEditChange = (e) => this.setState({ [e.target.name]: e.target.value });

  handleLogin = async () => {
    let { email, password } = this.state;
    let { toggleLoader, login } = this.props;
    let isValis = validator.isEmail(email);
    if (!password) return EventBus.publish("error", "Please enter password");
    if (!isValis) return EventBus.publish("error", "Please enter valid email");
    toggleLoader({ status: true, message: "User login..." });
    login({ email, password })
  }

  loginGoggle = async (credentialResponse) => {
    let { toggleLoader, login } = this.props;
    let decoded = jwt_decode(credentialResponse['credential']);
    let isValis = validator.isEmail(decoded['email']);
    if (!isValis) return EventBus.publish("error", "Please enter valid email");
    toggleLoader({ status: true, message: "User login..." });
    login({ email: decoded['email'], name: decoded['name'], googleId: decoded['sub'].toString() });
  }

  componentDidMount() {
    this.props.getRegisterUser({});
    this.props.getUserHaveEmail(false)
    this.props.getVerifyCode(false)
    this.props.getUserForget(false)
  }

  responseGoogle = (response) => {
    console.log(response);
  };

  render() {
    let { email, password } = this.state;

    if (this.props.isLogin) return <Redirect to="/" />
    return (
      <div className="login-page">
        <GoogleOAuthProvider clientId="886318186331-mheecsbaalsnp2qiomn6llg31f4gb57h.apps.googleusercontent.com">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="log-area">
                  <Link to="/"> <img src="images/logo.png" alt="" /></Link>
                </div>
              </div>
            </div>
          </div>
          <div className="form-area">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 offset-lg-3 col-md-12">
                  <div className="form-block">
                    <div className="title-area">
                      <p className="login-title">Sign In</p>
                    </div>
                    <ValidatorForm className="validator-form">
                      <div className='form-group'>
                        <label htmlFor='email'>Email </label>
                        <TextValidator
                          fullWidth
                          type='email'
                          name='email'
                          value={email}
                          id='standard-full-width'
                          className='form-input-field'
                          onChange={this.handleEditChange}
                        />
                      </div>
                      <div className='form-group'>
                        <label htmlFor='password'>Password </label>
                        <TextValidator
                          fullWidth
                          type='password'
                          name='password'
                          value={password}
                          id='standard-full-width'
                          className='form-input-field'
                          onChange={this.handleEditChange}
                        />
                      </div>
                      <div className='form-group btn-group'>
                        <button type="Submit" className='btn-style-one' onClick={this.handleLogin}>
                          {this.props.setLoader.status
                            ? <i className="fa fa-spinner fa-spin fa-fw"></i>
                            : 'SIGN IN'
                          }
                        </button>
                        <GoogleLogin
                          onSuccess={credentialResponse => {
                            this.loginGoggle(credentialResponse);
                          }}
                          onError={() => {
                            console.log('Login Failed');
                          }}
                        />
                      </div>
                      <div className='form-group links-group'>
                        <Link to="/Forgot" className="frog-pass">Forgot Password?</Link>
                        <p>New user? <Link to="/SignUp" className="req-pass"> Sign up</Link></p>
                      </div>
                    </ValidatorForm>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </GoogleOAuthProvider>
      </div>
    );
  }
}

const mapDispatchToProps = { toggleLoader, login, getRegisterUser, getVerifyCode, getUserHaveEmail, getUserForget };

const mapStateToProps = ({ Auth }) => {
  let { setLoader, isLogin } = Auth
  return { setLoader, isLogin }
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);