import { connect } from 'react-redux';
import { Link, useParams, withRouter } from "react-router-dom";
import ProgressBar from 'react-bootstrap/ProgressBar';


import './index.css';
import '../../static/css/animate.css';
import 'animate.css';
import '../../static/css/animate.css';
import { web3 } from '../../store/web3';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import { toggleLoader,getSingleVotings,addVote } from "../../store/actions/Auth";
import { useEffect, useState } from 'react';

const CastVote = (props) => {
    let [allvotes,setAllVotes] = useState(0);
    let {id} = useParams();
    const now = 60;
    useEffect(()=>{
        props.toggleLoader({message:"Load voting items...", status:true});
        props.getSingleVotings({id})
    },[]);

    const submitVote =(itemId,votingId)=> {
        if(!props.isLogin) return props.history.push("/Login");
        props.toggleLoader({message:"Vote submitting...", status:true});
        props.addVote({itemId,votingId})
    }

    useEffect(()=>{
        let OverAllVotes = 0;
        if(props.votingItems.length > 0){
            for (let index = 0; index < props.votingItems.length; index++) {
                const element = props.votingItems[index];
                OverAllVotes = OverAllVotes+element['totalVotes']
            }
        }
        setAllVotes(OverAllVotes);
    },[props.votingItems])

    return (
        <div className="wrapper">
            <Navbar />
            <section class="inner-page-banner">
                <div class="auto-container">
                    <div class="row">
                        <div class="col-12">
                            <h1>Cast Vote</h1>
                            <ul className="bredcamb">
                                <li><Link className="bredcamb-link" to="/">Home</Link></li>
                                <li>/</li>
                                <li><Link className="bredcamb-link" to="/Voting">Voting</Link></li>
                                <li>/</li>
                                <li><Link className="bredcamb-link active" to="#">Cast Vote</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section class="voting-single-sec gallery-page">
                <div class="auto-container">
                    <div class="row">
                        <div class="col-12">
                            <div className='title-area'>
                                <h2>{props.votings.title ? props.votings.title : ""}</h2>
                                <p>{props.votings.description ? props.votings.description : ""}</p>
                            </div>
                            {props.votingItems && props.votingItems.length > 0 && 
                            <div className='voting-box'>
                                <div className='row'>
                                    {props.votingItems.map((items,index)=>
                                    <div className='col-lg-6 col-md-12 col-sm-12' key={index}>
                                        <div className="voting-item">
                                            <div className="img-box">
                                                <img src={items?.['image']} alt="" /> 
                                                {/* <img src="images/cast-vote-1.png" alt="" /> */}
                                            </div>
                                            <div className="text-box">
                                                <h3>{items?.title}</h3>
                                                {items?.description.includes("Instagr") ?
                                                 <p><a href={items?.description.includes("https://") ? items.description : "https://"+items.description} target='_blank'> {items?.description} </a></p>
                                                :
                                                <p>{items?.description}</p>
                                                }
                                                {/* <label>{
                                                props.votings.totalVotes !== 0 ?
                                                `${Number(items['totalVotes']/props.votings.totalVotes*100).toFixed(2)}%` : 0+"%"}</label>
                                                    <ProgressBar now={Number(items['totalVotes']/props.votings.totalVotes*100)} /> */}
                                                    <label>{
                                                    allvotes !== 0 ?
                                                        `${Number(items['totalVotes']/allvotes*100).toFixed(2)}%`
                                                    : 0+"%"
                                                    }
                                                    </label>
                                                <ProgressBar now={Number(items['totalVotes']/allvotes*100)} />
                                                <button className='btn-style-one' onClick={()=>submitVote(items['_id'],id)}>Vote</button>
                                            </div>
                                        </div>
                                    </div>
                                    )}
                                    {/* <div className='col-lg-6 col-md-12 col-sm-12'>
                                        <div className="voting-item">
                                            <div className="img-box">
                                                <img src="images/cast-vote-2.png" alt="" />
                                            </div>
                                            <div className="text-box">
                                                <h3>Item Name</h3>
                                                <p>Lorem Ipsum is simply dummy text of the printiiig and typesetting istry. Lorem Ipsum is simply dummy text of the printiiig and typesetting industry. </p>
                                                    <label>{`${now}%`}</label>
                                                    <ProgressBar now={now} />
                                                <Link className='btn-style-one' to="#">Vote</Link>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div >
    );
}

const mapDispatchToProps = { toggleLoader,getSingleVotings,addVote };

const mapStateToProps = ({ Auth }) => {
    let { publicAddress,setSingleVoting, isLogin} = Auth;
    let votingItems = setSingleVoting.votingItems 
    let votings =  setSingleVoting.votings
    return { publicAddress,votingItems,votings,isLogin }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CastVote));